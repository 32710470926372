import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./App.css";
import { HomeLandingPage } from "./Components/Home/HomeLandingPage/HomeLandingPage";
import { ContactUs } from "./Components/ContactUs/ContactUs";
import { AboutUs } from "./Components/AboutUs/AboutUs";
import { Services } from "./Components/Services/Services";
import { OnlineCourses } from "./Components/Courses/OnlineCourses/OnlineCourses";
import { OnlineViewDetail } from "./Components/Courses/OnlineViewDetail/OnlineViewDetail";
import { OfflineCourses } from "./Components/Courses/OfflineCourses/OfflineCourses";
import { Career } from "./Components/Career/Career";
import { LoggedInLandingPage } from "./Components/LoggedInScreens/LoggedInLandingPage/LoggedInLandingPage";
import { Notification } from "./Components/LoggedInScreens/Notification/Notification";
import { EditProfile } from "./Components/LoggedInScreens/EditProfile/EditProfile";
import { MyCoursesMoreInfo } from "./Components/LoggedInScreens/MyCoursesMoreInfo/MyCoursesMoreInfo";
import { PurchaseHistory } from "./Components/LoggedInScreens/PurchaseHistory/PurchaseHistory";
import { Login } from "./Components/Login/Login";
import { LoginOTP } from "./Components/LoginOTP/LoginOTP";
import { Register } from "./Components/Register/Register";
import { ZoomSchedule } from "./Components/LoggedInScreens/ZoomSchedule/ZoomSchedule";
import { MyCourseCard } from "./Components/LoggedInScreens/CourseCard/CourseCard";
import { AllCourses } from "./Components/LoggedInScreens/AllCourses/AllCourses";
import { CourseViewDetails } from "./Components/LoggedInScreens/CourseViewDetails/CourseViewDetails";
import { Certificate } from "./Components/LoggedInScreens/Certificate/Certificate";
import { WebinarLanding } from "./Components/Webinar/WebinarLanding/WebinarLanding";
import { WebinarView } from "./Components/Webinar/WebinarView/WebinarView";
import { Routes, Route } from "react-router-dom";
import ZoomPage from "./Components/LoggedInScreens/ZoomSchedule/ZoomPage";
import WebinarZoom from "./Components/WebinarZoom/WebinarZoom";
import MCQ from "./Components/MCQ/MCQ";
import MCQTopic from "./Components/MCQ/MCQTopic";
import MCQQuestions from "./Components/MCQ/MCQQuestions";
import MCQResult from "./Components/MCQ/MCQResult";
import PageNotFound from "./Components/404/404";
import FindCertificate from "./Components/E_Certificate/FindCertificate";
import ViewCertifivcate from "./Components/E_Certificate/ViewCertifivcate";
import { PopUpAlert } from "./Components/PopUpAlert/PopUpAlert";
import Chatbot from "./Components/chatbot/chatbot";
import { TermsConditions } from "./Components/TermsConditions";
import { PrivacyPolicy } from "./Components/PrivacyPolicy";

function App() {
  const [isAlertSubmit, setIsAlertSubmit] = useState(false);

  function checkCookie() {
    let isAlert = document.cookie.split(";");
    for (let i of isAlert) {
      let alertKey = i.split("=");

      let aKey = alertKey[0].trim();
      if (aKey === "isAlert") {
        return parseInt(alertKey[1]);
      }
    }

    return 0;
  }

  async function closeAlert() {
    setIsAlertSubmit(true);
    setTimeout(() => {
      setIsAlertSubmit(false);
    }, 1000 * 60);
  }

  // useEffect(() => {
  //   const doc = window.document;
  //   const handleScroll = (e) => {
  //     var scrollPositionRatio =
  //       window.scrollY /
  //       (document.documentElement.scrollHeight - window.innerHeight);
  //     var accurateScrollPosition = Math.floor(scrollPositionRatio * 100); // Percentage-based scroll position

  //     console.log("Accurate scroll position:", accurateScrollPosition + "%");

  //     appbar.style.width = `${accurateScrollPosition}%`;
  //   };

  //   doc.addEventListener("scroll", handleScroll);

  //   // Cleanup function to remove the event listener when the component is unmounted
  //   return () => {
  //     doc.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <Provider store={store}>
      <>
        {/* {!checkCookie() && !isAlertSubmit && (
          <PopUpAlert isSubmit={setIsAlertSubmit} closeAlert={closeAlert} />
        )} */}
        <Chatbot />
        {/* <button onClick={remov}>SetCooie</button> */}

        <Routes>
          <Route exact path="*" element={<PageNotFound />} />
          <Route exact path="/" element={<HomeLandingPage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/onlinecourses" element={<OnlineCourses />} />
          <Route
            path="/onlinecourses/viewdetails/:courseId/:courseName"
            element={<OnlineViewDetail />}
          />
          <Route path="/offlinecourses" element={<OfflineCourses />} />
          <Route path="/career" element={<Career />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/*START --------Job Portal--------START */}
          {/* <Route path="/job-portal" element={<JPLandingPage />} /> */}

          {/* <Route
            path="job-portal/recruiter/create-password"
            element={<JPCRCreatePassword />}
          /> */}

          {/* <Route path="job-portal/candidate/verify" element={<JPCVerify />} /> */}

          {/* candidate */}
          {/* <Route
            path="job-portal/candidate"
            element={<CandidateAuthorization />}
          >
            <Route path="profile" element={<JPCP />} />
            <Route path="data-collect" element={<JPCRDataCollect />} />
            <Route path="profile-edit" element={<JPCProfileEdit />} />
            <Route path="update-password" element={<JPCUpdatePassword />} />
          </Route>

          <Route
            path="job-portal/candidate/verify/:token"
            element={<JPCVerified />}
          />
          <Route path="job-portal/candidate/login" element={<JPCL />} />
          <Route path="job-portal/candidate/register" element={<JPCR />} />
          <Route
            path="job-portal/candidate/forget-password"
            element={<CandidateForgetPassword />}
          />
          <Route
            path="job-portal/candidate/reset-password/:token"
            element={<CandidateNewPassword />}
          /> */}

          {/* candidate */}

          {/* recruiter */}

          {/* <Route
            path="job-portal/recruiter"
            element={<RecruiterAuthorization />}
          >
            <Route path="dashboard" element={<JPRecruiterDashboard />}>
              <Route path="profile/:id" element={<CandidateProfileView />} />
              <Route path="home" element={<JPRHome />} />
              <Route path="settings" element={<JPRSettings />}>
                <Route path="" element={<JPRSettingsLanding />} />
                <Route path="update-profile" element={<JPRUpdateProfile />} />
                <Route path="update-password" element={<JPRUpdatePassword />} />
              </Route>
            </Route>
          </Route> */}

          {/* <Route
            path="job-portal/recruiter/login"
            element={<JPRecruiterLogin />}
          />
          <Route
            path="job-portal/recruiter/register"
            element={<JPRecruiterRegister />}
          /> */}

          {/* recruiter */}

          {/* END--------Job Portal-------END */}

          <Route path="/login" element={<Login />} />
          <Route path="/mcq" element={<MCQ />} />
          <Route path="/mcq/topics/:collectionId" element={<MCQTopic />} />
          <Route path="/mcq/result/:resultId" element={<MCQResult />} />
          <Route
            path="/mcq/test/:collectionId/:topic"
            element={<MCQQuestions />}
          />
          <Route path="/verify/e_certificate" element={<FindCertificate />} />
          <Route
            path="/view/e_certificate/:certificateId"
            element={<ViewCertifivcate />}
          />
          <Route path="/loginotp" element={<LoginOTP />} />
          <Route path="/register/:mobileNumber" element={<Register />} />
          <Route path="/webinar/:webinarId" element={<WebinarLanding />} />
          <Route path="/webinarview" element={<WebinarView />} />
          <Route path="webinar/user/join/:userId" element={<WebinarZoom />} />

          <Route path="/dashboard" element={<LoggedInLandingPage />}>
            <Route path="schedule/:courseId" element={<ZoomSchedule />} />
            <Route path="mycourses" element={<MyCourseCard />} />
            <Route path="/dashboard/zoompage" element={<ZoomPage />} />
            <Route path="/dashboard/allcourses" element={<AllCourses />} />
            <Route path="/dashboard/notification" element={<Notification />} />
            <Route path="/dashboard/editprofile" element={<EditProfile />} />
            <Route
              path="/dashboard/viewdetails/:courseId"
              element={<CourseViewDetails />}
            />
            <Route
              path="/dashboard/mycoursemoreinfo/:courseId"
              element={<MyCoursesMoreInfo />}
            />
            <Route
              path="/dashboard/purchasehistory"
              element={<PurchaseHistory />}
            />
            <Route path="/dashboard/certificate" element={<Certificate />} />
          </Route>
        </Routes>
      </>
    </Provider>
  );
}

export default App;
