import "./Style/OnlineViewDetailStyle.scss";
import React, { useState, useEffect } from "react";
import { SideNavbar } from "../../SideNavbar/SideNavbar";
import { closeSideNavbar } from "../../Functions/SidebarFunction";
import { AiOutlineClockCircle, AiOutlineVideoCamera } from "react-icons/ai";
import { VscCalendar } from "react-icons/vsc";
import { RiTimerFlashLine } from "react-icons/ri";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BiCaretDownCircle } from "react-icons/bi";
import { AppbarCombo } from "../../AppbarCombo/AppbarCombo";
// thamizh added file
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../../index";
import useRazorpay from "react-razorpay";
import handlePayment from "../../Payment/RazorPayFunction";

export function OnlineViewDetail() {
  const [courseInfo, setCourseInfo] = useState({ syllabus: [] });
  const [userInfo, setUserInfo] = useState({});
  const param = useParams();
  const navigate = useNavigate();
  const [isExpand, setIsExpand] = useState(0);
  const Razorpay = useRazorpay();
  const [courseBuyingInfo, setCourseBuyingInfo] = useState({
    batchTime: "9-10",
    batchType: "week-days",
    query: "",
    token: localStorage.getItem("token"),
  });

  // Skeleton loading state
  const [isLoading, setIsLoading] = useState(true);

  function onchangeHandler(e) {
    const { name, value } = e.target;
    setCourseBuyingInfo({ ...courseBuyingInfo, [name]: value });
  }

  function isCheck() {
    axios
      .post("purchased/" + param.courseId, {
        token: localStorage.getItem("token"),
        isCheck: true,
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        console.log(res.data);
        if (!res.data.isPurchased) {
          handlePayment(Razorpay, courseInfo.price, {
            successCallback: buyCourse,
            courseInfo: courseInfo,
            userInfo: userInfo,
          });
        } else {
          alert(res.data.error);
        }
      })
      .catch((e) => {
        console.log(e);
        console.log("You have to login First");
        alert("You have to login First");
        navigate("/login");
      });
  }

  async function buyCourse() {
    await axios
      .post("purchased/" + param.courseId, {
        ...courseBuyingInfo,
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
          alert(res.data.error);
          navigate("/dashboard/mycourses");
        } else {
          console.log(res.data);
          alert("coursePurchased");
          navigate("/dashboard/mycourses");
        }
      })
      .catch((error) => {
        console.log("You have to login First");
        alert("You have to login First");
        navigate("/login");
      });
  }

  function gtCourseInfo() {
    axios
      .get("course/" + param.courseId)
      .then((res) => {
        console.log(res.data);
        setCourseInfo(res.data);
        setIsLoading(false); // Stop loading when course data is fetched
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  function getUserInfo() {
    axios
      .post("user/", { token: localStorage.getItem("token") })
      .then((res) => {
        console.log(res.data);
        setUserInfo(res.data);
        setIsLoading(false); // Stop loading when user data is fetched
      })
      .catch((e) => {
        console.log(e.message);
      });
  }

  useEffect(() => {
    gtCourseInfo();
    getUserInfo();
  }, []);

  const btnHover = (e) => {
    const doc = window.document;
    const btn = doc.querySelector(".card > .col > .purchaseBtn");

    console.log(btn.offsetLeft);
    console.log(e.nativeEvent.pageX);

    const x = e.nativeEvent.pageX - btn.offsetLeft;
    const y = e.nativeEvent.pageY - btn.offsetTop;
    btn.style.setProperty("--x", x + "px");
    btn.style.setProperty("--y", y + "px");
  };

  return (
    <>
      <AppbarCombo />
      <SideNavbar />
      <main className="OnlineViewDetail" onClick={closeSideNavbar}>
        <div className="bgColor">
          <div className="maxWidth">
            <section className="topbar">
              <section className="col1">
                <article
                  className="backBtn"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <IoIosArrowRoundBack className="icon" />
                  <span>Online Course</span>
                </article>
                <br />
                <h1 className="font1">
                  {isLoading ? "Loading..." : courseInfo.courseName} Certificate Development Course
                </h1>
                {isLoading ? (
                  <div className="skeleton-loader"></div>
                ) : (
                  <article className="row">
                    <div className="main-icon">
                      <AiOutlineClockCircle className="icon" />
                      Online Course
                    </div>
                    <div className="main-icon">
                      <AiOutlineVideoCamera className="icon" />
                      By Zoom
                    </div>
                  </article>
                )}
              </section>

              <section className="col2">
                {isLoading ? (
                  <div className="skeleton-loader"></div>
                ) : (
                  <div className="card">
                    <img
                      className="image"
                      src={courseInfo.courseImage}
                      alt="courses image"
                    />
                    <div className="col">
                      <div className="row">
                        <div className="col1-content">
                          <article className="col1-content-row">
                            <AiOutlineClockCircle className="icon" />
                            <select
                              className="dropdown"
                              name="batchTime"
                              value={courseBuyingInfo.batchTime}
                              onChange={onchangeHandler}
                            >
                              <option value="9-10">9-10</option>
                              <option value="10-11">10-11</option>
                              <option value="11-12">11-12</option>
                              <option value="1-2">1-2</option>
                              <option value="2-3">2-3</option>
                              <option value="3-4">3-4</option>
                            </select>
                          </article>
                          <article className="col2-content-row">
                            <VscCalendar className="icon" />
                            <select
                              className="dropdown"
                              name="batchType"
                              value={courseBuyingInfo.batchType}
                              onChange={onchangeHandler}
                            >
                              <option value="week-days">Week-Days</option>
                              <option value="week-end">Week-End</option>
                            </select>
                          </article>
                          <article className="col3-content-row">
                            <RiTimerFlashLine className="icon" />
                            <span className="font">
                              {courseInfo.duration} Hrs
                            </span>
                          </article>
                        </div>
                        <div className="col2-content">₹ {courseInfo.price}</div>
                      </div>
                      <div
                        className="purchaseBtn"
                        onClick={() => {
                          isCheck();
                        }}
                      >
                        <span>Enroll Now</span>
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </section>
          </div>
        </div>

        <section className="viewDetails-contents">
          <div className="viewDetails-maxwidth">
            <div className="course-details">
              <h2>Course Details</h2>
              {isLoading ? (
                <div className="skeleton-loader"></div>
              ) : (
                <p>{courseInfo.description}.</p>
              )}
              <br />
              <h2>Table Of Content</h2>
              {isLoading ? (
                <div className="skeleton-loader"></div>
              ) : (
                courseInfo.syllabus &&
                courseInfo.syllabus.map((syllabus, index) => {
                  return (
                    <div key={index} className="viewDetails-Details">
                      <div
                        className="viewDetails-Details-row"
                        onClick={() => {
                          if (isExpand === index + 1) {
                            setIsExpand(0);
                          } else {
                            setIsExpand(index + 1);
                          }
                        }}
                      >
                        <p>{syllabus.title}</p>
                        <p>
                          <BiCaretDownCircle style={{ fontSize: "25px" }} />
                        </p>
                      </div>
                      <div
                        className="viewDetails-Details-row-content"
                        style={{
                          display: index + 1 === isExpand ? "block" : "none",
                        }}
                      >
                        {syllabus.topics &&
                          syllabus.topics.map((topic, index) => {
                            return <p key={index}>{topic}</p>;
                          })}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
