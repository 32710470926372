import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Style/ServicesStyle.scss";
import { Titlebar } from "../Titlebar/Titlebar";
import { Footer } from "../Footer/Footer";
import { SideNavbar } from "../SideNavbar/SideNavbar";
import { closeSideNavbar } from "../Functions/SidebarFunction";
import { AppbarCombo } from "../AppbarCombo/AppbarCombo";
import Image from "../Image/team.png";
import campusTraining from "../Image/services_svg/campus-training.png";
import softwareDevelopment from "../Image/services_svg/software-development.png";
import technicalWorkshop from "../Image/services_svg/technical workshop.png";
import counseling from "../Image/services_svg/counseling.png";
import webinar from "../Image/services_svg/wbinar.png";
import careerOriented from "../Image/services_svg/career_oriented.svg";
import corporateTraining from "../Image/services_svg/corporate_training.svg";
import learnNewSkill from "../Image/services_svg/learn_new_skill.svg";
import workshopsAndValue from "../Image/services_svg/workshops_and_value.svg";

export function Services() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <AppbarCombo />
      <SideNavbar />
      <Titlebar title="SERVICES" />
      <div className="services" onClick={closeSideNavbar}>
        <h1>OUR SERVICES</h1>
        <div className="row">
          <div className="card">
            <img src={campusTraining} width={200} alt="On-campus training services at Ocean Academy" />
            <h3>On-Campus training</h3>
            <p>
              We offer on-campus learning for students of various Universities
              and Colleges and help facilitate knowledge and develop their
              careers.
            </p>
          </div>

          <div className="card">
            <img src={softwareDevelopment} width={200} alt="Software development services including design, planning, and testing" />
            <h3>Software development</h3>
            <p>
              We offer various software development services such as designing,
              planning, and testing and also provide maintenance.
            </p>
          </div>

          <div className="card">
            <img src={technicalWorkshop} width={200} alt="Technical workshops and implant training at Ocean Academy" />
            <h3>Technical Workshops (Implant training)</h3>
            <p>
              We devise and plan workshops targeted to the practical needs of
              students and explore new nuances in IT technology.
            </p>
          </div>

          <div className="card">
            <img src={counseling} width={200} alt="IT consulting and data analysis services at Ocean Academy" />
            <h3>IT consulting</h3>
            <p>
              We provide data analysis services to companies, manage their data
              and infrastructure, and guide them according to their goals and
              needs using our technologies.
            </p>
          </div>

          <div className="card">
            <img src={Image} width={200} alt="Placement support services including resume preparation and mock interviews" />
            <h3>Placement Support</h3>
            <p>
              We assist candidates with resume preparation and mock interviews
              to prepare them for all the challenges of technical interviews, by
              not limiting that we provide extended support during and after
              placements.
            </p>
          </div>

          <div className="card">
            <img src={webinar} width={200} alt="Free webinars for students and professionals at Ocean Academy" />
            <h3>Free Webinars</h3>
            <p>
              The easiest way to host a webinar for free is to use a live
              streaming platform like Facebook Live or YouTube Live. The
              benefits include easy use, nearly unlimited participant counts,
              and simple event marketing.
            </p>
          </div>
        </div>

        <div className="description-row">
          <img data-aos="fade-up-right" src={corporateTraining} alt="Corporate training to enhance skills for professionals" />
          <div data-aos="fade-left">
            <h3>Hone your skills with Corporate Training</h3>
            <p>
              Corporate Training enables a professional or a skilled jobseeker
              to improve their current skills in required areas of expertise.
              The Ocean Academy provides professional work experience and helps
              equip trainees with the expectations of the corporate world.
            </p>
          </div>
        </div>

        <div className="description-row">
          <div data-aos="fade-right" id="description">
            <h3>Start a new career with Career-oriented courses</h3>
            <p>
              Start learning new technologies from scratch and build a solid
              foundation for your career. Career courses offered by Ocean
              Academy-a golden opportunity for graduate students - cover the
              basics of the course up to the level of expertise required.
            </p>
          </div>
          <img data-aos="fade-up-left" id="img" src={careerOriented} alt="Career-oriented courses to start a new career at Ocean Academy" />
        </div>

        <div className="description-row">
          <img data-aos="fade-up-right" src={learnNewSkill} alt="Workshops and value-added courses to learn new skills" />
          <div data-aos="fade-left">
            <h3>
              Experience latest technologies through Workshops & Value-added
              Courses
            </h3>
            <p>
              Workshops are a great way to learn new innovations by further
              investigating your own or by current methods encouraged by us
              within a short period of time.
            </p>
          </div>
        </div>

        <div className="description-row">
          <div data-aos="fade-right" id="description">
            <h3>Learn new skills through Software training</h3>
            <p>
              Software training provides the knowledge of technologies that you
              deem to be necessary for your career or education. This enables
              learning new product features and ad hoc functions of a known
              application to improve productivity and facilitates one to work
              more independently.
            </p>
          </div>
          <img
            data-aos="fade-up-left"
            id="img"
            src={workshopsAndValue}
            alt="Software training for career and education enhancement"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
