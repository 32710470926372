import "./Style/_JourneyStyle.scss";
import React, { useEffect } from "react";
import s from "./images/s.svg";
import d from "./images/d2.svg";
import w from "./images/w.svg";
import AOS from "aos";
import "aos/dist/aos.css";

export function Journey() {
  useEffect(() => {
    AOS.init({
      // duration : "2s"
    });
  }, []);
  return (
    <div className="journey">
      <h1
        data-aos="fade-left"
        data-aos-offset="100"
        data-aos-easing="ease-in-sine"
      >
        Our Journey Through The Years
      </h1>
      <p
        data-aos="fade-left"
        data-aos-offset="100"
        data-aos-easing="ease-in-sine"
      >
        Throughout the years since our inception, we have bestowed upon students
        with our wisdom and, the help of our trainers, we have initiated and
        successfully run various programs to date.
      </p>
      <div className="contentBox">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
        >
          <img src={s} alt="Free seminars in pondicherry" height={70} />
          <h2>75+ Free seminars</h2>
          <p>
            Inspired by our way of teaching, many students from different states
            in India have attended our free workshops, which empowers us to
            advance our noble cause.
          </p>
        </div>

        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
        >
          <img src={d} alt="IT students trained in software" height={70} />
          <h2>10000+ Students trained</h2>
          <p>
            As a prominent institute in Puducherry, we have successfully trained
            5500+ students, inculcated our knowledge, and encouraged curiosity
            in them.
          </p>
        </div>

        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="500"
        >
          <img src={w} alt="International workshops conducted in pondicherry" height={70} />
          <h2>6 International Workshop</h2>
          <p>
            As the workshop conducted by Ocean Academy is in a class all by
            itself, we have completed 6 International workshops and provided
            hands-on training for students.
          </p>
        </div>
      </div>
    </div>
  );
}
