import React from "react";
import "./Style/StudentPlacementStyle.scss";
import Amazon from "../../Image/company_images/amazon.png";
import Capgenini from "../../Image/company_images/capgemini.png";
import Cognizant from "../../Image/company_images/cognizant.png";
import Esale from "../../Image/company_images/esales.png";
import Google from "../../Image/company_images/google.png";
import Hcl from "../../Image/company_images/HCL.png";
import Hexaware from "../../Image/company_images/hexaware.png";
import Infosys from "../../Image/company_images/infosys.png";
import Lenovo from "../../Image/company_images/lenovo.png";
import Tcs from "../../Image/company_images/TCS.png";
import Deloitte from "../../Image/company_images/deloitte.png";
import Techmahindra from "../../Image/company_images/techmahindra.png";
import Accenture from "../../Image/company_images/accenture.png";
import IBM from "../../Image/company_images/ibm.png";
import LTI from "../../Image/company_images/mindtree.png";


export function StudentPlacement() {
  return (
    <div className="studentPlacement">
      <h1>Our Students Work At</h1>
      <p>
        We not only enlighten students with our knowledge but also help develop
        their careers. Our students have grabbed placement in various firms and
        MNCs.
      </p>
      <div className="row">
        <img src={Tcs} alt="TCS Placement Opportunities for Training Institute Students" />
        <img src={Google} alt="Google Placement Opportunities for Training Institute Students" />
        <img src={Amazon} alt="Amazon Placement Opportunities for Training Institute Students" />
        <img src={Capgenini} alt="Capgemini Placement Opportunities for Training Institute Students" />
        <img src={Cognizant} alt="Cognizant Placement Opportunities for Training Institute Students" />
        <img src={Hcl} alt="Hcl Placement Opportunities for Training Institute Students" />
        <img src={Infosys} alt="Infosys Placement Opportunities for Training Institute Students" />
        <img src={Lenovo} alt="Lenovo Placement Opportunities for Training Institute Students" />
        <img src={Hexaware} alt="Hexaware Placement Opportunities for Training Institute Students" />
        <img src={Esale} alt="Esale Placement Opportunities for Training Institute Students" />
        <img src={Deloitte} alt="Deloitte Placement Opportunities for Training Institute Students" />
        <img src={Techmahindra} alt="TechMahindra Placement Opportunities for Training Institute Students"  />
        <img src={Accenture} alt="Accenture Placement Opportunities for Training Institute Students" />
        <img src={IBM} alt="IBM Placement Opportunities for Training Institute Students" />
        <img src={LTI} alt="LTI Placement Opportunities for Training Institute Students" />
    </div>
    </div>
  );
}
