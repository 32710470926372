import React, { useEffect, useState } from "react";
import "./Style/_TechFestBanner.scss";
import TECHFEST_LOGO from "../../Image/techfest/techfest.svg";
import bookTicketsImage from "../../Image/techfest/bookyourtickets.png";

const TechFestLayout = () => {
  const [confettiArray, setConfettiArray] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  // Move handleTicketClick here, outside the useEffect hook
  const handleTicketClick = () => {
    // Redirect to the booking page or show a modal for booking
    window.open("https://techfest.oceanacademy.co.in/", "_blank");

  };

  useEffect(() => {
    const confettiCount = 100;
    const confettiElements = Array.from(
      { length: confettiCount },
      (_, index) => ({
        id: index,
        color: `hsl(${Math.random() * 360}, 100%, 70%)`, // Random color
        left: `${Math.random() * 100}vw`, // Random position
        animationDelay: `${Math.random() * 2}s`, // Random delay
      })
    );
    setConfettiArray(confettiElements);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile breakpoint
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty dependency array means this runs only on mount

  return (
    <section className="tech-fest-layout" onClick={handleTicketClick}>
      {/* Confetti Popper */}
      <div className="confetti-container">
        {confettiArray.map((confetti) => (
          <div
            key={confetti.id}
            className="confetti"
            style={{
              backgroundColor: confetti.color,
              left: confetti.left,
              animationDelay: confetti.animationDelay,
            }}
          />
        ))}
      </div>

      {isMobile ? (
        // Mobile View
        <div className="combined-section">
          <div className="techfest-content">
            <div style={{ display: "flex" }} className="techImg">
              <img src={TECHFEST_LOGO} alt="TechFest 2025" width="100%" />
              <div className="book-tickets-section">
                <div
                  className="book-tickets-image dancing"
                  onClick={handleTicketClick}
                >
                  <img
                    src={bookTicketsImage}
                    alt="Book Your Tickets Now!"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div className="text-section">
              <h1 className="title">
                A National Level Technical Festival - Pondicherry
              </h1>
              <p className="details">
                📅 March 2nd | 📍 PTU - Auditorium | ⏰ 08:30 AM to 5:30 PM
              </p>
            </div>
          </div>
        </div>
      ) : (
        // Desktop View
        <>
          <div className="techImg">
            <img src={TECHFEST_LOGO} alt="TechFest 2025" />
          </div>
          <div className="center-section">
            <h1 className="title">
              A National Level Technical Festival - Pondicherry
            </h1>
            <p className="details">
              📅 March 2nd | 📍 PTU - Auditorium | ⏰ 08:30 AM to 5:30 PM
            </p>
          </div>
          <div
            className="book-tickets-image dancing"
            onClick={handleTicketClick}
          >
            <img src={bookTicketsImage} alt="Book Your Tickets Now!" />
          </div>
        </>
      )}
    </section>
  );
};

export default TechFestLayout;
