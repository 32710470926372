import "./Style/WebinarForm.scss";
import React, { useState } from "react";
import axios from "../../../index";
import { useNavigate, useParams } from "react-router-dom";
import useRazorpay from "react-razorpay";
import handlePayment from "../../Payment/RazorPayFunction";

export function WebinarForm({
  title,
  subtitle,
  startAt,
  isFree,
  bannerImage,
  mentorName,
  coutDown,
}) {
  const param = useParams();
  const [webinarUser, setWebinarUser] = useState({
    username: "",
    email: "",
    mobileNumber: "",
    webinar: param.webinarId,
  });
  const [fieldValidator, setFieldValidator] = useState({
    username: true,
    email: true,
    mobileNumber: true,
  });
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  function onChangeHandler(event) {
    const { name, value } = event.target;
    setWebinarUser({ ...webinarUser, [name]: value });
  }
  const emailPattan = /^\w+([\.-]?\w)*@\w+([\.-]?\w*)*(\.[a-z\D]{2,3})$/gi;
  const numberPattan = /^\d{10}$/;
  const stringPattan = /^([a-zA-Z\s]{3})\s?([a-zA-Z\s]*)$/;
  function validator() {
    let username = true;
    let email = true;
    let mobileNumber = true;
    if (!stringPattan.test(webinarUser.username)) {
      username = false;
    }
    if (!emailPattan.test(webinarUser.email)) {
      email = false;
    }
    if (!numberPattan.test(webinarUser.mobileNumber)) {
      mobileNumber = false;
    }

    setFieldValidator({
      username: username,
      email: email,
      mobileNumber: mobileNumber,
    });
    return username && email && mobileNumber;
  }

  function registerWebinar() {
    console.log(webinarUser);
    axios
      .post("/webinar/user/add", webinarUser)
      .then((res) => {
        console.log(res.data);
        setWebinarUser({
          username: "",
          email: "",
          mobileNumber: "",
          webinar: param.webinarId,
        });
        navigate("/webinarview");
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  function registerWebinarWithPayment() {
    let userInfo = {
      firstName: webinarUser.username,
      email: webinarUser.email,
      mobileNumber: webinarUser.mobileNumber,
    };
    if (validator()) {
      if (isFree === 0) {
        registerWebinar();
      } else {
        handlePayment(Razorpay, isFree, {
          successCallback: registerWebinar,
          courseInfo: { courseName: title },
          userInfo: userInfo,
        });
      }
    } else {
      console.log("invalid form field");
    }
  }
  return (
    <section className="webinarForm">
      <p className="title">{title}</p>
      <p className="subTitle">{subtitle}</p>
      <section className="row">
        <section className="left">
          <img src={bannerImage} alt="banner image"></img>
          <p>With {mentorName}</p>
        </section>
        <section className="right">
          <section className="timer">
            <div className="time">
              <span>
                {coutDown.days > 9 ? coutDown.days : `0${coutDown.days}`}
              </span>
              <span>:</span>
              <span>
                {coutDown.hours > 9 ? coutDown.hours : `0${coutDown.hours}`}
              </span>

              <span>:</span>
              <span>
                {coutDown.minutes > 9
                  ? coutDown.minutes
                  : `0${coutDown.minutes}`}
              </span>

              <span>:</span>
              <span>
                {coutDown.seconds > 9
                  ? coutDown.seconds
                  : `0${coutDown.seconds}`}
              </span>
            </div>

            <div className="day">
              <span>DAY</span>
              <span></span>
              <span>HRS</span>
              <span></span>
              <span>MIN</span>
              <span></span>
              <span>SEC</span>
            </div>
          </section>

          <p>Webinar Start In</p>
          <input
            type="text"
            placeholder="Name"
            name="username"
            value={webinarUser.username}
            onChange={onChangeHandler}
          />
          {!fieldValidator.username && (
            <p style={{ color: "red" }}>invalid username</p>
          )}
          <input
            type="number"
            placeholder="Phone Number"
            name="mobileNumber"
            value={webinarUser.mobileNumber}
            onChange={onChangeHandler}
          />
          {!fieldValidator.mobileNumber && (
            <p style={{ color: "red" }}>invalid mobileNumber</p>
          )}
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={webinarUser.email}
            onChange={onChangeHandler}
          />
          {!fieldValidator.email && (
            <p style={{ color: "red" }}>invalid email</p>
          )}
          <div className="btn" onClick={registerWebinarWithPayment}>
            <span>Join</span>
          </div>
          <p>
            {/* // TODO: terms and condition */}
            TODO: terms and condition Lorem ipsum dolor sit amet, consectetur
            adipisicing elit. Aspernatur soluta, voluptates, impedit ullam illum
            dignissimos veniam expedita
          </p>
        </section>
      </section>
      <div className="circleLg"></div>
      <div className="circle"></div>
      <div className="square"></div>
      <div className="triangle"></div>
    </section>
  );
}
