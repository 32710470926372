import React from "react";
import "./Style/Clients.scss";
import Adhiparasakthi from "../../Image/clients_images/Adhiparasakthi.png";
import CK from "../../Image/clients_images/CK.png";
import MIT from "../../Image/clients_images/MIT.png";
import Panimalar from "../../Image/clients_images/Panimalar.png";
import PU from "../../Image/clients_images/PU.png";
import SMVC from "../../Image/clients_images/SMVC.png";

export function Clients() {
  return (
    <div className="clients">
      <h1>Our Clients</h1>
      <p>
        Various Educational Institutes and Universities prefer our pioneering
        training methods for their students.
      </p>
      <br />
      <div className="row">
  <img src={MIT} alt="MIT Training Institute Placements and Opportunities" />
  <img src={PU} alt="PU Training Institute for Skill Development and Careers" />
  <img src={Panimalar} alt="Panimalar Training Institute for IT and Engineering Placements" />
  <img src={Adhiparasakthi} alt="Adhiparasakthi Institute for Advanced Technical Training" />
  <img src={SMVC} alt="SMVC Training Institute Offering Job-Oriented Courses" />
  <img src={CK} alt="CK College with Top Training Programs for Students" id="ckClg" />
</div>

    </div>
  );
}
